import React, { Component } from 'react'
import { Provider } from 'react-redux'
import store from './utils/store'
import { ConfigProvider } from 'antd'
import Router from './routes'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n';
import { authenticate } from './modules/app.module'
import { HelmetProvider } from 'react-helmet-async'
import brasil from 'antd/lib/locale/pt_BR'

class App extends Component {
  componentDidMount() {
    store.dispatch(authenticate())
  }

  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <HelmetProvider>
          <Provider store={store}>
            <ConfigProvider locale={brasil}>
              <Router />
            </ConfigProvider>
          </Provider>
        </HelmetProvider>
      </I18nextProvider>
    )
  }
}

export default App
