import { call, all, put, takeEvery, takeLatest, take, select, delay } from 'redux-saga/effects'
import { saga } from 'utils/firebase'
import * as actions from './actions'
import { resolvePromiseAction }   from '@adobe/redux-saga-promise'

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* syncConfiguracoesSaga() {
  const channel = saga.firestore.channel('configuracoes');

  while(true) {
    const todos = yield take(channel);
    yield put(watchFetchData(todos));
  }
}

function* syncBlogSaga() {
  const channel = saga.firestore.channel('configuracoes');

  while(true) {
    const todos = yield take(channel);
    yield put(watchFetchData(todos));
  }
}

function* watchFetchData() {
  yield takeEvery('WEBSITE_SET_CONFIG', handleAction)
}

function * handleAction (action) {
  const { seconds, value } = action.payload
  yield delay(seconds*1000)
  yield call(resolvePromiseAction, action, value)
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/


export default function* rootSaga() {
  yield all([

  ])
  // code after all-effect
}
