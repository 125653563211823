import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { storage } from 'utils/firebase'
import { Link } from 'react-router-dom'
import Connector from 'utils/connector'
import Loadable from 'react-loadable'
import { Badge, Divider, BackTop, Layout } from 'antd'
import { configs } from 'theme'
import { PageHeader, Menu, Dropdown, Button } from 'antd'
import { Row, Col } from 'antd'
import { Avatar, Tag, Space, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import 'assets/antd.dark.css';
import 'theme/app.css';
import ModuleStatus from './Rotines/ModuleStatus'
import InternetTest from './Rotines/InternetTest'
import ErrorDetect from './Rotines/ErrorDetect'
import DarkMode from './Rotines/DarkMode'
import {
  PushpinOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Toaster } from 'react-hot-toast';
import './helpers/toast'

import { useNetwork } from 'ahooks';
const { Header, Content, Footer } = Layout

const Contact = Loadable({
  loader: () => import('./Rotines/Contact'),
  loading: () => <Spin />,
})

const CacheProcessor = Loadable({
  loader: () => import('./Rotines/CacheProcessor'),
  loading: () => <Spin />,
})

const Language = Loadable({
  loader: () => import('./Rotines/LanguageSelect'),
  loading: () => <Spin />,
})

const WebsiteStatus = Loadable({
  loader: () => import('./Rotines/WebsiteStatus'),
  loading: () => <Spin />,
})

window.configs = configs;

const Body = props => {
  const networkState = useNetwork();
  const { t } = useTranslation()
  const [avatar, setAvatar] = useState(false)

  const routes = [
    {
      path: window.configs.painel_path,
      breadcrumbName: t('common:BREADCRUMB_HOME'),
    },
    {
      path: props.global.path_01_url.toString(),
      breadcrumbName: props.global.path_01.toString(),
    },
    {
      path: window.location.href,
      breadcrumbName: props.global.path_02.toString(),
    },
  ]

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={route.path}>{route.breadcrumbName}</Link>
    );
  }

  const konamiCode = [
    'arrowup',
    'arrowup',
    'arrowdown',
    'arrowdown',
    'arrowleft',
    'arrowright',
    'arrowleft',
    'arrowright',
    'b',
    'a',
    'enter'
  ];

  useEffect(() => {
    storage
      .ref()
      .child('default/profile.png')
      .getDownloadURL()
      .then(url => setAvatar(url))
  }, [])

  const menu = <Menu>
    <Menu.Item onClick={() => props.actions.logout()}>Sair</Menu.Item>
  </Menu>

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
          success: {
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
      <Sidebar />
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <Row justify="space-between" style={{ paddingLeft: 24, paddingRight: 24 }}>
            <Col>
              <Space>
                <CacheProcessor />
                {process.env.REACT_APP_MULTILANGUAGE == "true" ? <Language /> : <></>}
              </Space>
            </Col>
            <Col>
              <Space size="middle">
                <WebsiteStatus />
                <Dropdown overlay={menu} arrow={true} trigger={['click']}>
                  <Space size="middle">
                    <Badge dot={networkState.online} color="lime" offset={[-5, 20]}>
                      {avatar ? (
                        <Avatar src={avatar} />
                      ) : (
                        <Avatar icon={<LoadingOutlined />} />
                      )}
                    </Badge>
                    <Tag icon={<PushpinOutlined />} color="gold">
                      {props.me.me.displayName}
                    </Tag>
                  </Space>
                </Dropdown>
                <DarkMode />
              </Space>
            </Col>
          </Row>
        </Header>
        <Content style={{ margin: '0' }}>
          <PageHeader
            ghost={false}
            title={props.global.title.toString()}
            className="site-page-header"
            avatar={{ src: "https://gw.alipayobjects.com/zos/rmsportal/nxkuOJlFJuAUhzlMTCEe.png" }}
            subTitle={props.global.modulo && props.global.subtitle.toString()}
            tags={props.global.modulo && <ModuleStatus domain={props.global.modulo.toString()} />}
            extra={[
              <Button key="1" type="primary" onClick={() => document.location.reload(true)}>
                {t('common:RELOAD_DATA')}
              </Button>,
              <Contact />,
            ]}
            onBack={() => window.history.back()}
            breadcrumb={{ itemRender: itemRender, routes }}
          />
          <ErrorDetect />
          <InternetTest />
          <div className="site-layout-background" style={{ margin: '24px 24px 0 24px' }}>
            {props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <Divider>
            {' '}
            <Badge status="success" />
            {t('common:PRODUCT_ACTIVE')}{' - '}v{process.env.REACT_APP_VERSION}
          </Divider>
          {t('common:COPYRIGHT', { year: new Date().getFullYear(), company: process.env.REACT_APP_COPYRIGHT })}
        </Footer>
        <BackTop />
      </Layout>
    </Layout>
  )
}

const ConnectedBody = props => (
  <Connector>
    {({ state, actions }) => <Body global={state.painel} me={state.app} actions={actions.app} {...props} />}
  </Connector>
)

Body.propTypes = {}
Body.defaultProps = {}

export default ConnectedBody
