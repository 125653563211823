import React from 'react';
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Cache from 'i18next-localstorage-cache';
import LanguageDetector from 'i18next-browser-languagedetector'
import i18next from 'i18next'
import modules from 'scenes/painel/modulos'

i18n
  .use(Cache)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'pt-BR',
    fallbackLng: 'pt-BR',
    debug: false,
    initImmediate: false,
    saveMissing: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    defaultTransParent: React.Fragment
  });

i18next.addResourceBundle('pt-BR', 'common', require('./i18n/pt/common.json'))

modules.map(module => module.i18n.map(locale => i18next.addResourceBundle(locale.locale, module.slug, locale.file)) )

window.i18n = i18n;

export default i18n
