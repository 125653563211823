import React from 'react'
import { firestore } from 'utils/firebase'
import { Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next'
import { useSessionStorageState } from 'ahooks';
import Connector from 'utils/connector'
import moment from 'moment';

const Delete = props => {
  const { t } = useTranslation();
  const [lang] = useSessionStorageState('language-db', window.i18n.language);

  const trash = () => {
    let doc = process.env.REACT_APP_MULTILANGUAGE == "true"
      ? firestore.collection(lang).doc(`data`).collection(props.db)
      : firestore.collection(props.db);

    doc
      .doc(props.id)
      .delete()
      .then(() => {
        window.toast.success(t('common:RECORD_DELETED'))
        props.actions.setCacheExpire(moment().unix())
      })
      .catch(error => {
        props.actions.setErrorData(error)
        props.actions.setErrorActive()
        window.toast.error(t('common:DELETE_ERROR'))}
      );
  }

  return (
    <Popconfirm
      title={t('common:CONFIRM_DELETE')}
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
      onConfirm={trash}>
      <a href="#">{t('common:COLUMN_DELETE')}</a>
    </Popconfirm>
  )
}

const ConnectedDelete = props => (
  <Connector>
   {({ actions }) => <Delete actions={actions.painel} {...props} />}
  </Connector>
)

Delete.propTypes = {}
Delete.defaultProps = {}

export default ConnectedDelete
