import React from 'react'
import LoadingScreen from 'react-loading-screen';
import { styler } from 'theme'

const styles = styler({
  img: {
    width: `150px`
  }
})

const Loading = () => {
  const group = "Frontend"

  return (
    <div className={styles}>
      <LoadingScreen
        loading={true}
        bgColor='#f1f1f1'
        spinnerColor='#354256'
        textColor='#354256'
        logoSrc={process.env.REACT_APP_LOADING_LOGO}
      />
    </div>
  )
}

export default Loading;
