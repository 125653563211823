import { createReducer } from 'redux-act';
import * as actions from './actions';

export default createReducer({
  [actions.setContent]: (state, payload) => ({ ...state, content: payload }),
  [actions.setTitle]: (state, payload) => ({ ...state, title: payload }),
  [actions.setMenu]: (state, payload) => ({ ...state, currentMenuSection: payload }),
  [actions.setConfiguracoes]: (state, payload) => ({ ...state, config: payload }),
}, {
  content: {},
  title: "--",
  config: {},
  currentMenuSection: "home"
});
