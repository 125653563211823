import logo from 'assets/images/logo.png'
import profile from 'assets/images/profile.png'
import wireframe from 'assets/images/web-wireframe.png'

const images = {
  logo,
  profile,
  wireframe
}

export default images
