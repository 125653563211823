import { configs } from 'theme'

const path = {
  logout: configs.painel_path + '/logout/',
  login: configs.painel_path + '/login/',
  signup: configs.painel_path + '/signup/',
  profile: configs.painel_path + '/dashboard/',
  resetPassword: configs.painel_path + '/reset-password/',
  confirmEmail: configs.painel_path + '/confirm-email/',
  home: configs.painel_path + '/home/',
}

const message = {
}

export {
  path,
  message,
}
