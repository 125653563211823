import React, { useEffect, useState } from 'react'
import { Tooltip, Tag, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDocument } from 'react-firebase-hooks/firestore';
import { firestore } from 'utils/firebase'

const ModuleStatus = props => {
  const [ active, setActive] = useState(false)
  const { t } = useTranslation()
  const [value, loading, error] = useDocument(
    firestore.collection('modulos').doc(`${props.domain}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if(error) {
      props.actions.setErrorData(error)
      props.actions.setErrorActive()
    }
  }, [error])

  useEffect(() => {
    if(value) {
      if(typeof value.data() !== 'undefined') {
        if(typeof value.data().status !== 'undefined') {
          setActive(value.data().status)
        }
      }
    }
  })

  return (
    <Spin spinning={loading}>
      {active ? (
        <Tooltip title="Seu módulo esta ativo e funcionando">
          <Tag color="success">{t('common:ACTIVE')}</Tag>
        </Tooltip>
      ) : (
        <Tooltip title="Voce não deveria estar visualizando">
          <Tag color="warning">{t('common:INACTIVE')}</Tag>
        </Tooltip>
      )}
    </Spin>
  )
}

export default ModuleStatus
