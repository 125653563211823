import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import CsvDownloader from 'react-csv-downloader'
import { useSessionStorageState } from 'ahooks'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { v4 as uuid } from 'uuid'
import { firestore } from 'utils/firebase'

const CSV = () => {
  const [lang] = useSessionStorageState('language-db', window.i18n.language);
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])
  let path = window.location.pathname.split( '/' );
  const config  = require(`templates/${process.env.REACT_APP_THEME}/admin/modulos/${path[2]}/config`).default;
  const [values, loading] = useCollectionData(
    typeof config.disable_order !== `undefined`
      ? firestore.collection(config.db).orderBy('created_at', 'desc')
      : process.env.REACT_APP_MULTILANGUAGE == true
        ? firestore.collection(lang).doc(`data`).collection(config.db).orderBy('order', 'asc')
        : firestore.collection(config.db).orderBy('created_at', 'desc'),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    },
  )

  useEffect(() => {
    if(values) {
      let formated = window._.tail(config.fields).map(i => {
        return {
          id: i.name,
          displayName: i.name
        }
      })
      setColumns(formated)
      setData(values)
    }
  }, loading)

  return <CsvDownloader
    filename={`exportacao_${config.db}_${uuid()}`}
    separator=";"
    wrapColumnChar="'"
    columns={columns}
    datas={data}
    text="Exportar em .csv">
    <Button type="primary" loading={loading} disabled={loading} danger>Exportar em .csv</Button>
  </CsvDownloader>;
};

export default CSV;
