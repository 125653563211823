import Blog from './modulos/blog';
import Banners from './modulos/banners';
import Categorias from './modulos/categorias';
import Newsletter from './modulos/newsletter';
import Oportunidades from './modulos/oportunidades';
import Contatos from './modulos/contatos';

export default [
  Banners,
  Blog,
  Categorias,
  Contatos,
  Newsletter,
  Oportunidades
];
