import React, { useState } from 'react';
import { Switch, useDarkreader } from 'react-darkreader';

export default () => {
  const [isDark, { toggle, collectCSS  }] = useDarkreader(false);

  const [CSS, setCSS] = useState(
    '/** Here is some css string generated by react-darkreader **/',
  );

  const logCSS = async () => {
    const CSS = await collectCSS();
    setCSS(CSS);
  };

  return (
    <></>
  );
};