import React from 'react'
import Loadable from 'react-loadable'
import Loader from './inc/Loading';

const Home = Loadable({ loader: () => import('./pages/Home'), loading: () => <Loader />, })

const Blog = Loadable({ loader: () => import('./pages/Blog'), loading: () => <Loader />, })
const Post = Loadable({ loader: () => import('./pages/Post'), loading: () => <Loader />, })
const Categoria = Loadable({ loader: () => import('./pages/Categoria'), loading: () => <Loader />, })
const Busca = Loadable({ loader: () => import('./pages/Busca'), loading: () => <Loader />, })
const Tag = Loadable({ loader: () => import('./pages/Tag'), loading: () => <Loader />, })

export default {
  routeProps: [{
    path: `/`,
    component: Home,
    name: "Home",
    exact: true,
    strict: false
  }, {
    path: `/blog`,
    component: Blog,
    name: "Blog",
    exact: true,
    strict: false
  }, {
    path: `/post/:slug`,
    component: Post,
    name: "Página do Blog",
    exact: false,
    strict: false
  }, {
    path: `/busca/`,
    component: Busca,
    name: "Busca do Blog",
    exact: true,
    strict: false
  }, {
    path: `/tag/:slug`,
    component: Tag,
    name: "Tag do Blog",
    exact: false,
    strict: false
  }, {
    path: `/categoria/:slug`,
    component: Categoria,
    name: "Categoria do Blog",
    exact: false,
    strict: false
  }],
};
