import React from 'react';
import { Trans } from 'react-i18next';
import { Space } from 'antd';
import { Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

let searchInput = null;

const getColumnSearchProps = (dataIndex, __NAMASPACE_) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          searchInput = node;
        }}
        placeholder={`${window.i18n.t(`common:SEARCH_BUTTON_SEARCH`)} ${window.i18n.t(`${__NAMASPACE_}:FIELDS_02`)}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => {
          confirm();
          window.sessionStorage.setItem(`${__NAMASPACE_}-search-text`, selectedKeys[0])
          window.sessionStorage.setItem(`${__NAMASPACE_}-search-dataIndex`, dataIndex)
        }}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm();
            window.sessionStorage.setItem(`${__NAMASPACE_}-search-text`, selectedKeys[0])
            window.sessionStorage.setItem(`${__NAMASPACE_}-search-dataIndex`, dataIndex)
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}>
          <Trans i18nKey={`common:SEARCH_BUTTON_SEARCH`} />
        </Button>
        <Button onClick={() => {
          clearFilters();
          window.sessionStorage.setItem(`${__NAMASPACE_}-search-text`, '')
        }} size="small" style={{ width: 90 }}>
          <Trans i18nKey={`common:SEARCH_BUTTON_CLEAR`} />
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
            window.sessionStorage.setItem(`${__NAMASPACE_}-search-text`, selectedKeys[0])
            window.sessionStorage.setItem(`${__NAMASPACE_}-search-dataIndex`, dataIndex)
          }}>
            <Trans i18nKey={`common:SEARCH_BUTTON_FILTER`} />
        </Button>
      </Space>
    </div>
  ),

  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
  render: text =>
    window.sessionStorage.getItem(`${__NAMASPACE_}-search-dataIndex`) === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[window.sessionStorage.getItem(`${__NAMASPACE_}-search-text`)]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});

export default getColumnSearchProps
