import React from 'react';
import Loadable from 'react-loadable'
import { configs } from 'theme'
import config from './config'
import Loader from 'scenes/painel/inc/Loader';
import store from 'scenes/painel/modulos/base/redux'
import { ReadOutlined } from '@ant-design/icons';
import * as actions from 'scenes/painel/modulos/base/redux/actions';

export default {
  routeProps: {
    path: `${configs.painel_path}/${config.path}`,
    component: Loadable({
      loader: () => import('scenes/painel/modulos/base/Router'),
      loading: () => <Loader />
    })
  },
  Redux: store,
  Actions: actions,
  icon: <ReadOutlined />,
  name: config.title,
  slug: config.path,
  db: config.db,
  i18n: [{
    locale: "pt-BR",
    file: require('./i18n/pt.json')
  }]
};
