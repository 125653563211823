import toast from 'react-hot-toast';

window.toast = toast
window.toaster = async promise => toast.promise(
  promise,
  {
    loading: window.i18n.t('common:PROCESSING'),
    success: () => window.i18n.t('common:RECORD_DELETED'),
    error: error => `Erro: ${error.toString()}`,
  },
  {
    style: {
      minWidth: '250px',
    },
    success: {
      duration: 5000,
      icon: '🔥',
    },
  }
);
