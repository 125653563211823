import React from 'react';
import { useNetwork } from 'ahooks';
import { Alert } from 'antd';
import moment from 'moment'

const InternetTest = () => {
  const networkState = useNetwork();

  return (
    !networkState.online ? <Alert type="warning" message={`Sua conexão foi perdida às ${moment().to(moment(networkState.sinc))}`} banner /> : <></>
  );
};

export default InternetTest;
