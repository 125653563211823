import { createAction } from 'redux-act';

export const setTitle = createAction('SET_TITLE');
export const getTitle = createAction('GET_TITLE');
export const setPath01 = createAction('SET_PATH_01');
export const setPath02 = createAction('SET_PATH_02');
export const setModulo = createAction('SET_MODULO');
export const setSubModulo = createAction('SET_SUBMODULO');
export const setCacheExpire = createAction('SET_EXPIRE_CACHE');
export const setErrorActive = createAction('SET_ERROR_ACTIVE');
export const setErrorInactive = createAction('SET_ERROR_INACTIVE');
export const setErrorData = createAction('SET_ERROR_DATA');
