import React from 'react';
import { Trans } from 'react-i18next';
import { Tag, Space, Divider } from 'antd';
import { configs } from 'theme';
import { Link } from 'react-router-dom';
import Delete from 'scenes/painel/modulos/base/pages/Delete'
import { generatePath } from "react-router";
import { sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import getColumnSearchProps from 'scenes/painel/inc/helpers/columnSearch'
import CSV from 'scenes/painel/modulos/base/components/CSV'

let __NAMASPACE_ = 'newsletter';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const config = {
  path: __NAMASPACE_,
  full_path: `${configs.painel_path}/${__NAMASPACE_}/`,
  title: <Trans i18nKey={`${__NAMASPACE_}:MODULE`} />,
  db: __NAMASPACE_,
  disable_order: true,
  buttons: <><CSV /></>,
  fields: [
    { name: 'id', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_01`} />, type: 'id' },
    { name: 'email', label: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_02`} />, type: 'text', validations: { required: true } },
  ],
  columns: [
    {
      title: <MenuOutlined style={{ color: '#999' }} />,
      dataIndex: 'sort',
      width: 40,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: <Trans i18nKey={`${__NAMASPACE_}:FIELDS_02`} />,
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      ...getColumnSearchProps('title', __NAMASPACE_)
    },
    {
      title: <Trans i18nKey={'common:COLUMN_OPTIONS'} />,
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Link to={generatePath(`${configs.painel_path}/${__NAMASPACE_}/editar/:uuid`, { uuid: record.uid })}><Trans i18nKey={'common:COLUMN_EDIT'} /></Link>
          <Divider type="vertical" />
          <Link to={generatePath(`${configs.painel_path}/${__NAMASPACE_}/visualizar/:uuid`, { uuid: record.uid })}><Trans i18nKey={'common:COLUMN_SHOW'} /></Link>
          <Divider type="vertical" />
          <Delete id={record.uid} db={__NAMASPACE_} />
        </Space>
      ),
    },
  ]
}

export default config
