import React from 'react';
import { Alert } from 'antd';
import moment from 'moment'
import Connector from 'utils/connector'

const ErrorDetect = props => {
  return (
    props.global.has_error ? <Alert closable type="error" message={`Erro: ${JSON.stringify(props.global.error)}`} banner afterClose={() => props.actions.setErrorInactive()} /> : <></>
  );
};

const ConnectedErrorDetect = props => (
  <Connector>
    {({ state, actions }) => <ErrorDetect global={state.painel} actions={actions.painel} {...props} />}
  </Connector>
)

ErrorDetect.propTypes = {}
ErrorDetect.defaultProps = {}

export default ConnectedErrorDetect
