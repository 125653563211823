import React, { useState } from 'react';
import { Layout, Menu, Divider, Typography } from 'antd';
import {
  HomeOutlined,
  HddOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  FireOutlined,
  AppstoreAddOutlined,
  HistoryOutlined
} from '@ant-design/icons';
import { styler, images } from 'theme'
import modules from '../modulos';
import { configs } from 'theme';
import { useHistory } from "react-router-dom";
import { CgCodeSlash } from "react-icons/cg";
const { Text } = Typography;

const { Sider } = Layout;

const styles = styler({
  container: {
    width: '100%',
    height: 64,
    padding: '10.5px 7px',
  },
  logo: {
    objectFit: 'contain',
    objectPosition: 'center center',
    width: '100%',
    filter: 'invert(100%)'
  },
})

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [opened, setOpened] = useState('home');
  const [selected, setSelected] = useState('home');
  let history = useHistory();

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
      <div className={styles.container}>
        <img src={images.logo} className={styles.logo} alt="logo" />
      </div>
      <Menu
        theme="light"
        defaultSelectedKeys={[opened]}
        defaultOpenKeys={[selected]}
        mode="inline">
        <Menu.Item key="home" icon={<HomeOutlined />} onClick={() => {
          setSelected('home')
            history.push(configs.painel_path);
          }}>
          {window.i18n.t('common:PANEL')}
        </Menu.Item>
        {modules.map((module, k) =>
          <Menu.Item key={k} icon={module.icon}  onClick={() => {
            setSelected(module.name)
            history.push(module.routeProps.path);
          }}>
            {module.name}
          </Menu.Item>
        )}
        <Divider plain><Text type="secondary">Customização</Text></Divider>
        <Menu.Item key="configuracoes" icon={<SettingOutlined />} onClick={() => {
          setSelected('configuracoes')
          history.push(`${configs.painel_path}/configuracoes`);
        }}>
          {window.i18n.t('common:CONFIGS')}
        </Menu.Item>
        <Divider plain><Text type="warning"><CgCodeSlash /> Desenvolvedor</Text></Divider>
        <Menu.Item key="arquivos" icon={<HddOutlined />} onClick={() => {
          setSelected('arquivos')
          history.push(`${configs.painel_path}/arquivos`);
        }}>
          {window.i18n.t('common:FILES')}
        </Menu.Item>
        <Menu.Item key="temas" icon={<FireOutlined />} onClick={() => {
          setSelected('temas')
          history.push(`${configs.painel_path}/temas`);
        }}>
          {window.i18n.t('common:THEMES')}
        </Menu.Item>
        <Menu.Item key="usuarios" icon={<UsergroupAddOutlined />} onClick={() => {
          setSelected('usuarios')
          history.push(`${configs.painel_path}/usuarios`);
        }}>
          {window.i18n.t('common:USERS')}
        </Menu.Item>
        <Menu.Item key="modulos" icon={<AppstoreAddOutlined />} onClick={() => {
          setSelected('modulos')
          history.push(`${configs.painel_path}/modulos`);
        }}>
          {window.i18n.t('common:MODULES')}
        </Menu.Item>
        <Menu.Item key="versoes" icon={<HistoryOutlined />} onClick={() => {
          setSelected('versoes')
          history.push(`${configs.painel_path}/versoes`);
        }}>
          {window.i18n.t('common:VERSION')}
        </Menu.Item>
      </Menu>
    </Sider>
  )
}

export default Sidebar;
