import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga'
import { reduxFirestore, firestoreReducer } from 'redux-firestore';
import firebase from 'utils/firebase'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import app from '../modules/app.module'
import modules from 'scenes/painel/modulos'
import painel from 'scenes/painel/inc/redux'
import { promiseMiddleware } from '@adobe/redux-saga-promise'

const activeTheme = require(`templates/${process.env.REACT_APP_THEME}/redux`).default;
const activeThemeSaga = require(`templates/${process.env.REACT_APP_THEME}/redux/sagas`).default;
const sagaMiddleware = createSagaMiddleware();

const analytics = () => next => action => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: action.type,
    payload: action.payload,
  })

  return next(action)
}

// Redux store config
const configureStore = (initialState = {}) => {
  const reducers = combineReducers({
    app,
    painel,
    website: activeTheme,
    ...modules.map(i => ({ [i.name]: i.Redux.reducer ?? undefined })),
    firestore: firestoreReducer
  })

  // Middleware and store enhancers
  const middlewares = [
    thunk,
    process.env.NODE_ENV !== 'production' && logger,
    analytics,
    promiseMiddleware,
    sagaMiddleware
  ].filter(Boolean)

  const enhancer = composeWithDevTools(applyMiddleware(...middlewares))

  const rfConfig = {};

  const createStoreWithFirebase = compose(
    reduxFirestore(firebase, rfConfig), // firebase instance as first argument, rfConfig as optional second
  )(createStore);

  const store = createStoreWithFirebase(reducers, initialState, enhancer)

  return store
}

const store = configureStore()

sagaMiddleware.run(activeThemeSaga);

export default store
