import React, { useEffect } from 'react'
import Loadable from 'react-loadable'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Connector from 'utils/connector'
import { colors, configs } from 'theme'
import Body from 'scenes/painel/inc/Body'
import modules from './scenes/painel/modulos';
import { Helmet } from 'react-helmet-async';
import Loader from 'scenes/painel/inc/Loader';
import { useTranslation } from 'react-i18next'
import { path } from 'utils/const'
import { Debug, AuthenticatedRoute } from 'react-router-util';

const Loading           = require(`templates/${process.env.REACT_APP_THEME}/inc/Loading`).default;
const TemplateSelected  = require(`templates/${process.env.REACT_APP_THEME}`).default;
const Dashboard         = Loadable({ loader: () => import('./scenes/painel/globais/dashboard'), loading: () => <Loader /> })
const Arquivos          = Loadable({ loader: () => import('./scenes/painel/globais/media'), loading: () => <Loader /> })
const Configuracoes     = Loadable({ loader: () => import('./scenes/painel/globais/configuracoes'), loading: () => <Loader /> })
const Modulos           = Loadable({ loader: () => import('./scenes/painel/globais/modulos'), loading: () => <Loader /> })
const Temas             = Loadable({ loader: () => import('./scenes/painel/globais/temas'), loading: () => <Loader /> })
const Versoes           = Loadable({ loader: () => import('./scenes/painel/globais/versoes'), loading: () => <Loader /> })
const Usuarios          = Loadable({ loader: () => import('./scenes/painel/globais/usuarios'), loading: () => <Loader /> })
const NoMatch           = Loadable({ loader: () => import('./scenes/painel/pages/404'), loading: () => <Loader /> })
const ErrorPage         = Loadable({ loader: () => import('./scenes/painel/pages/500'), loading: () => <Loader /> })
const NoMatchPublic     = Loadable({ loader: () => import('./templates/base/pages/404'), loading: () => <Loader /> })
const Login             = Loadable({ loader: () => import('./scenes/auth/Login'), loading: () => <Loader /> })
const ConfirmEmail      = Loadable({ loader: () => import('./scenes/auth/ConfirmEmail'), loading: () => <Loader /> })
const ResetPassword     = Loadable({ loader: () => import('./scenes/auth/ResetPassword'), loading: () => <Loader /> })
const Logout            = Loadable({ loader: () => import('./scenes/auth/Logout'), loading: () => <Loader /> })
const Signup            = Loadable({ loader: () => import('./scenes/auth/Signup'), loading: () => <Loader /> })

const Router = ({ checked, loggedIn }) => {
  const { t } = useTranslation()
  useEffect(() => {
    Object.keys(colors).forEach(key => {
      const cssKey = `--${key}`
      const cssVal = colors[key]
      document.body.style.setProperty(cssKey, cssVal)
    })
  }, [])

  if (!checked) return <></>
  return (
    <BrowserRouter>
      <Debug/>
        <Switch>
          {TemplateSelected.routeProps.map((page, k) => (
            <Route {...page} key={k} />
          ))}
          <Redirect path={`/home`} to={`/`} />
          <Route path={`${path.login}`} name="Login" component={Login} />
          <Route path={`${path.confirmEmail}`} name="Confirmar email" component={ConfirmEmail} />
          <Route path={`${path.resetPassword}`} name="Resetar senha" component={ResetPassword} />
          <Route path={`${path.logout}`} name="Logout" component={Logout} />
          <Redirect path={`/login`} to={`${path.login}`} />
          <Redirect path={`/adm`} to={`${configs.painel_path}`} />
          <Redirect path={`/admin`} to={`${configs.painel_path}`} />
          <Redirect path={`/gerenciamento`} to={`${configs.painel_path}`} />
          <AuthenticatedRoute
            isAuthenticated={loggedIn ? true : false}>
            <Body>
              <React.Fragment>
                <Helmet titleTemplate={`%s - ${process.env.REACT_APP_COPYRIGHT}`}>
                  <title>{t(`common:LOADING`)}</title>
                  <meta charSet="utf-8" />
                  <link rel="apple-touch-icon" sizes="57x57" href={require('assets/images/favicon/apple-icon-57x57.png')} />
                  <link rel="apple-touch-icon" sizes="60x60" href={require('assets/images/favicon/apple-icon-60x60.png')} />
                  <link rel="apple-touch-icon" sizes="72x72" href={require('assets/images/favicon/apple-icon-72x72.png')} />
                  <link rel="apple-touch-icon" sizes="76x76" href={require('assets/images/favicon/apple-icon-76x76.png')} />
                  <link rel="apple-touch-icon" sizes="114x114" href={require('assets/images/favicon/apple-icon-114x114.png')} />
                  <link rel="apple-touch-icon" sizes="120x120" href={require('assets/images/favicon/apple-icon-120x120.png')} />
                  <link rel="apple-touch-icon" sizes="144x144" href={require('assets/images/favicon/apple-icon-144x144.png')} />
                  <link rel="apple-touch-icon" sizes="152x152" href={require('assets/images/favicon/apple-icon-152x152.png')} />
                  <link rel="apple-touch-icon" sizes="180x180" href={require('assets/images/favicon/apple-icon-180x180.png')} />
                  <link rel="icon" type="image/png" sizes="192x192" href={require('assets/images/favicon/android-icon-192x192.png')} />
                  <link rel="icon" type="image/png" sizes="32x32" href={require('assets/images/favicon/favicon-32x32.png')} />
                  <link rel="icon" type="image/png" sizes="96x96" href={require('assets/images/favicon/favicon-96x96.png')} />
                  <link rel="icon" type="image/png" sizes="16x16" href={require('assets/images/favicon/favicon-16x16.png')} />
                  <meta name="msapplication-TileColor" content="#000" />
                  <meta name="msapplication-TileImage" content={require('assets/images/favicon/ms-icon-144x144.png')} />
                  <meta name="theme-color" content="#000" />
                  <link rel="canonical" href={window.location.href} />
                </Helmet>
                <Switch>
                  <Route path={`${configs.painel_path}`} name="Home" exact component={Dashboard} />
                  <Route path={`${configs.painel_path}/dashboard`} name="Dashboard" exact component={Dashboard} />
                  {modules.map((module, k) => (
                    <Route {...module.routeProps} name={module.name} key={k} />
                  ))}
                  <Route path={`${configs.painel_path}/temas`} name="Temas" exact component={Temas} />
                  <Route path={`${configs.painel_path}/arquivos`} name="Arquivos" exact component={Arquivos} />
                  <Route path={`${configs.painel_path}/configuracoes`} name="Configurações" exact component={Configuracoes} />
                  <Route path={`${configs.painel_path}/modulos`} name="Modulos" exact component={Modulos} />
                  <Route path={`${configs.painel_path}/versoes`} name="Versões" exact component={Versoes} />
                  <Route path={`${configs.painel_path}/usuarios`} name="Usuários" exact component={Usuarios} />
                  <Route path={`${configs.painel_path}/erro`} exact>
                    <ErrorPage />
                  </Route>
                  <Route path={`${configs.painel_path}/*`}>
                    <NoMatch />
                  </Route>
                </Switch>
              </React.Fragment>
            </Body>
          </AuthenticatedRoute>
          <Redirect from="*" to="/404" />
          <Route path="/404">
            <NoMatchPublic />
          </Route>
        </Switch>
    </BrowserRouter>
  )
}

export default props => (
  <Connector>
    {({
      state: {
        app: { loggedIn, checked, auth },
      },
    }) => <Router checked={checked} auth={auth} loggedIn={loggedIn} {...props} />}
  </Connector>
)
